// src/config.js
const Config = {
    baseURL: process.env.REACT_APP_DJANGO_BASE_URL || 'https://backend.algorithmix.online',
};

// const Config = {
//     baseURL: process.env.REACT_APP_DJANGO_BASE_URL || 'http://localhost:8000',
// };


export default Config;
